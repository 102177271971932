/* JS Extentions */
/* I will create custom interfaces for all extention that u use here */

//Made this extention to add groupby to js array
//To use go     [].groupBy("var1");  OR  [].groupBy(["var1" , "var2"]);
Array.prototype.groupBy = function (gbVars) {
    if (!gbVars) {
        return this;
    }
    if (gbVars.constructor !== Array) {
        gbVars = [gbVars];
    }

    var grpdObj = []; //Result goes here

    for (var i = 0, ii = this.length; i < ii; i++) { //Goes through passed array
        var grpd = false; //check to see if found matching group
        for (var x = 0, xx = grpdObj.length; x < xx; x++) { //Goes through new array
            var grpdC = 0; //check to see if found matching group (count to see if matched)
            for (var z = 0, zz = gbVars.length; z < zz; z++) { //Goes through variables that we are grouping by
                if (this[i][gbVars[z]] == grpdObj[x][gbVars[z]]) {
                    grpdC++;
                }
            }

            if (grpdC === gbVars.length) {
                grpdObj[x]["list"].push(this[i]);
                grpd = true;
                break;
            }
        }

        if (!grpd) {
            var newObj = { list: [this[i]] };

            for (var z = 0, zz = gbVars.length; z < zz; z++) { //Goes through variables that we are grouping by
                newObj[gbVars[z]] = this[i][gbVars[z]];
            }
            grpdObj.push(newObj);
        }
    }

    return grpdObj;
};

Array.prototype.flatten = function (variable) {
    if (!variable) {
        return this;
    }
    let resArray = [];
    for (var i = 0, ii = this.length; i < ii; i++) { //Goes through passed array
        if (this[i][variable].constructor === Array) {
            resArray = resArray.concat(this[i][variable]);
        } else {
            resArray = resArray.push(this[i][variable]);
        }
    }

    return resArray;
};

Date.prototype.getRoundedTime = function () {
    //Server send's a UTC time, just localize and show it
    return this.getTime();
}

String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};